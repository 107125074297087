$(function() {

  accordionLineOffsets();


  var launch;
  window.onresize = function() {
    clearTimeout(launch);
    launch = setTimeout(accordionLineOffsets, 100);
  };

  // $('.his-accordion').on('shown.bs.collapse', accordionLineOffsets);
  // $('.his-accordion').on('hidden.bs.collapse', accordionLineOffsets);

});





function accordionLineOffsets() {

  // calculate line offset for first and last card

  $('.his-accordion__card--first').each(function() {
    var $this = $(this),
        line = $this.find('.his-accordion__connection'),
        point = $this.find('.his-accordion__point'),
        offset = point.offset().top - $this.offset().top + 4;

    line.css('top', offset);
  });

  $('.his-accordion__card--last').each(function() {
    var $this = $(this),
        line = $this.find('.his-accordion__connection'),
        point = $this.find('.his-accordion__point'),
        offset = point.offset().top - $this.offset().top + 4;

    line.css('height', offset);
  });

}